.modalWrapper {
  @apply fixed z-40 inset-0 bg-black bg-opacity-25 flex backdrop-blur-sm justify-center items-center;

  .contentWrapper {
    @apply w-[600px] flex flex-col mx-auto relative z-50;

    .closeButton {
      @apply z-10 top-2 right-2 absolute text-white text-xl place-self-end;
    }

    .childrenWrapper {
      @apply pt-10 bg-gray-400 p-2 rounded;
    }
  }
}