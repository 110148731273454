.invitationShow {
  @apply flex w-full text-neutral-100 text-sm px-5 py-2 border-b font-medium dark:border-neutral-500;
}

.headerItem {
  @apply w-[33%];
}

.item {
  @apply w-[33%] flex justify-between;
}

.invitationHeader {
  @apply flex w-full text-neutral-100 text-sm px-5 py-2 border-b font-medium dark:border-neutral-500;
}