.container {
  @apply py-20 w-[350px] mx-auto;

  .formContainer {
    @apply w-full py-10;
  }

  .emailInput {
    @apply w-full px-3 border border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 my-2
    focus:border-blue-500 block h-[40px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
    dark:text-white
  }

  .submitButton {
    @apply rounded-lg w-full h-[40px] dark:bg-gray-700 text-gray-50 my-3
  }

  .submitButtonLoading {
    @apply rounded-lg w-full h-[40px] bg-gray-500 bg-opacity-25 text-gray-50 text-opacity-40 my-3
  }
}