.reportRowsWrapper {
  @apply bg-white border-b dark:bg-gray-800 dark:border-gray-700;

  .videoRow {
    @apply py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white;

    .videoModal {
      @apply object-fill w-[700px] h-[700px];
    }
  }

  .tableRow {
    @apply py-4 px-6 break-words max-w-[50px];
  }

  .buttonsWrapper {
    @apply flex justify-end;

    .acceptButton {
      @apply mx-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded;
    }

    .declineButton {
      @apply mx-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded;
    }
  }
}