.tableHeader {
  @apply text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400;

  .headerCol {
    @apply py-3 px-6;
  }

  .headerButtonsCol {
    @apply py-3 px-6 flex justify-end pr-20;
  }
}